.audio-recorder {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.audio-recorder > .visualizer {
  width: 100%;
  height: 54px;
  box-sizing: border-box;

  font-size: 0;
}

.audio-recorder > .visualizer canvas {
  width: 100%;
  height: 100%;
}

.audio-recorder > .controls {
  padding-top: 12px;
}

.audio-recorder > .controls > .button {
  cursor: pointer;
  padding: 10px;

  font-size: 0;
  color: 575757;

  background-color: white;
  border-radius: 50%;
  box-shadow: 0 1px 3px 0px #696969;
}

.audio-recorder > .controls > .button > svg {
  width: 30px;
  height: 30px;
}