.product {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;

  background-color: #ffff;
  border-radius: 10px;
  box-shadow: 0 1px 3px 0px #c7c7c7;

  transition: background-color 0.3s ease;
  animation: 1s linear product-fadein;
}

.product:hover {
  background-color: #ececec;
}

.product > .image  {
  width: 80px;
  min-width: 80px;
  height: 80px;
}

.product > .image > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.product .content {
  padding-left: 10px;
}

.product > .content > .name {
  padding: 3px 0px;

  font-size: 14px;
  font-weight: bold;
}

.product > .content > .type {
  padding: 3px 0px;

  font-size: 14px;
}

.product > .content > .category {
  padding: 3px 0px;

  font-size: 14px;
}

.product > .content > .price {
  padding: 3px 0px;

  font-size: 18px;
  font-weight: bold;
}

@keyframes product-fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

