.search {
  position: relative;
  width: 100%;
  height: 100%;
}

.search > .header {
  z-index: 10;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  background-color: #009eeb;
  box-shadow: 0 1px 3px 0px #696969;
}

.search > .header > .logo {
  padding-top: 10px;
}

.search > .header > .logo > svg {
  height: 60px;
}

.search > .header > .recorder {
  width: 100%;
  max-width: 600px;
  padding: 10px 30px;
  margin: 0 auto;
  box-sizing: border-box;
}

.search > .header > .loader {
  display: block;
  max-width: 1000px;
}

.search > .results {
  position: absolute;
  width: 100%;
  top: 215px;
  bottom: 0px;
  overflow-y: auto;
}

.search > .results > .container {
  max-width: 1000px;
  padding: 10px;
  margin: 0 auto;
  box-sizing: border-box;
}

.search > .results > .container > .product {
  margin-bottom: 10px;
}

.search > .results > .container > .product:last-child {
  margin-bottom: 0;
}

.search > .results > .empty {
  position: absolute;
  top: 50%;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;

  text-align: center;

  transform: translateY(-50%);
}
