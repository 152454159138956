.loading {
  width: 100%;
  height: 100%;
  position: relative;

  background-color: #009eeb;
}

.loading > .container {
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
}