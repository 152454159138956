.error {
  width: 100%;
  height: 100%;
  position: relative;

  background-color: #009eeb;
}

.error > .message {
  position: absolute;
  top: 50%;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;

  font-size: 30px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  text-transform: uppercase;

  transform: translateY(-50%);
}